.quote {
    background-color: var(--another-another-another-another-lighter-purple);
    border-radius: rem(40px);
    overflow: hidden;
    margin: rem(90px) 0;

    &__text {
        font-family: var(--thirsty);
        font-size: rem(40px);
        font-weight: 500;
        line-height: 1.458;
        text-align: center;
        padding: rem(20.84px);
        margin: 0;
        color: #894a5f;
    }
}
