.news-latest--thumbnail {
    .news-latest {
        &__image {
            border-radius: rem(40px);
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__headline {
            font-family: var(--gotham);
            font-size: rem(35px);
            font-weight: 700;
            line-height: 1;
            letter-spacing: 0.03em;
            text-align: left;
            margin-top: rem(48px);
            margin-bottom: rem(18px);
            color: var(--dark-green);
        }

        &__subheadline {
            font-family: var(--work);
            font-size: rem(20px);
            font-weight: 400;
            line-height: 1.2;
            text-align: left;
            margin: rem(18px) 0;
        }
    }
}
