/* global settings */
@import '../../nig2023/css/settings/_layout';
@import './settings/_colors.scss'; @import './settings/_images.scss';

/* functions and mixins */
@import '../../nig2023/css/functions/_breakpoints.scss'; @import '../../nig2023/css/functions/_grid.scss'; @import '../../nig2023/css/functions/_mixins.scss'; @import '../../nig2023/css/functions/_styles.scss';

/* component styles */
@import './components/_news-full.scss'; @import './components/_news-latest-full.scss'; @import './components/_news-latest-list.scss'; @import './components/_news-latest-thumbnail.scss'; @import './components/_sidebar-element.scss';

/* section styles */
@import './sections/_footer.scss';

/* module styles */
@import './modules/_mod_cfg_instagram.scss'; @import './modules/_mod_navigation_main.scss'; @import './modules/_mod_newsarchive.scss'; @import './modules/_mod_newslist.scss'; @import './modules/_mod_search.scss';

/* element styles */
@import './elements/_ce_form.scss'; @import './elements/_ce_image.scss'; @import './elements/_ce_rsce_author_list.scss'; @import './elements/_ce_rsce_blog_page_banner.scss'; @import './elements/_ce_rsce_blog_quote.scss';
