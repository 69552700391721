#footer {
    .nav-and-cookie {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.4em;
        margin-top: 12px;
        margin-bottom: 20px;

        @include larger() {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .cookie-link {
        display: block;
        font-size: 12px;
        font-weight: 700;
        text-decoration: none;

        @include larger() {
            font-size: vwmax(16px);
        }
    }
}
