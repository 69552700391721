.mod_newslist.blog--thumbnail {
    @include fwbg();
    padding-top: 1px;
    padding-bottom: 1px;

    &:before {
        background-image: url('../../../nig2023/images/background.svg');
        background-position: center center;
        opacity: 0.8;

        @include tablet() {
            opacity: 1;
        }
    }

    & > h2 {
        font-family: var(--thirsty);
        font-size: rem(50px);
        font-weight: 600;
        line-height: 72.9px;
        text-align: left;
        color: var(--dark-green);
        margin: rem(48px) 0;
    }

    .newslist {
        &__articles {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: rem(25px);
            padding-bottom: rem(40px);
        }
    }
}
