.news-latest--full {
    .news-latest {
        &__date {
            color: var(--blog-grey);
        }

        &__author {
            margin-top: vw(-31px);
            display: flex;
    
            @include desktop() {
                margin-top: -37px;
            }
    
            &-image {
                flex-shrink: 0;
                border-radius: vw(15px);
                border: vw(1px) solid black;
                overflow: hidden;
                margin-right: vw(11px);
    
                img {
                    width: vw(78px);
                }
    
                @include desktop() {
                    border-radius: 15px;
                    border: 1px solid black;
                    margin-left: 30px;
                    margin-right: 15px;
    
                    img {
                        width: auto;
                    }
                }
            }
    
            &-name {
                margin-top: vw(38px);
                font-size: vw(12px);
                font-weight: 300;
    
                @include desktop() {
                    margin-top: 57px;
                    font-size: 18px;
                }
            }
        }

        &__subheadline {
            margin: rem(18px) 0;
            font-size: rem(22px);
            font-weight: 500;
            margin-left: 12px;
            margin-right: 12px;

            @include tablet() {
                margin-left: 28px;
                margin-right: 28px;
            }
        }

        &__readon {
            &-button {
                font-family: var(--gotham);
                font-size: rem(18px);
                font-weight: 400;
                line-height: 1.2;
                letter-spacing: 0.2em;
                text-align: center;
                border: 1px solid var(--dark-green);
                background-color: transparent;
                box-sizing: border-box;
                outline: 0;
                cursor: pointer;
                display: block;
                height: rem(40px);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 rem(20px);
                border-radius: rem(20px);
                text-transform: uppercase;
                color: var(--dark-green);
                text-decoration: none;
                min-width: rem(280px);
                transition: color 0.2s, background-color 0.2s, border-color 0.2s;
                position: absolute;
                left: 12px;
                bottom: 0;

                @include tablet() {
                    left: 28px;
                }

                &:hover {
                    color: white;
                    background-color: var(--dark-green);
                    border-color: var(--dark-green);
                }
            }
        }

        &__content {
            flex-grow: 1;
            box-sizing: border-box;
            position: relative;
            padding-bottom: rem(40px + 20px);
        }
    }
}
