.ce_rsce_blog_page_banner.slider-image {
    .slider-image {
        &__content {
            @include tablet() {
                max-width: vwmax(475px);
            }
        }

        &__text-top {
            @include tablet() {
                font-size: vwmax(65px);
                line-height: 1.0769;
            }
        }

        &__text-bottom {
            @include tablet() {
                margin-top: vwmax(22px);
            }
        }
    }
}
