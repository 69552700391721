.ce_image {
    &--sidebar-facebook {
        .image_container {
            padding: rem(28px) rem(38px);
            position: relative;

            &:after {
                display: block;
                content: '';
                width: rem(47px);
                height: rem(47px);
                right: rem(23px + 38px);
                bottom: rem(21px + 28px);
                position: absolute;
                background-image: var(--thumbs-up);
                background-repeat: no-repeat;
                background-size: rem(47px) auto;
                pointer-events: none;
            }

            img {
                border-radius: rem(15px);
                overflow: hidden;
            }
        }
    }

    &.image--gartentelefon {
        @include fwbg(#f1f6dd);
        margin: 0;
    }
}
