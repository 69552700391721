.authorlist {
    display: grid;
    gap: rem(37px);
    margin: rem(90px) 0;

    @include desktop() {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }

    &__author {
        border: 1px solid black;
        border-radius: rem(40px);
        padding-top: rem(45px);
        padding-left: rem(32px);
        padding-right: rem(32px);
        padding-bottom: rem(50px);
    }

    &__image {
        display: flex;
        justify-content: center;
        margin-bottom: rem(18px);
    }

    &__name {
        font-family: var(--gotham);
        font-size: rem(30px);
        font-weight: 700;
        line-height: 1.1666;
        letter-spacing: 0.03em;
        text-align: center;
        color: var(--dark-green);
        margin-top: rem(18px);
        margin-bottom: rem(8px);
    }

    &__fieldOfActivity {
        font-family: var(--work);
        font-size: rem(22px);
        font-weight: 700;
        line-height: 1.1731;
        letter-spacing: 0.03em;
        text-align: center;
        margin-top: rem(8px);
        margin-bottom: rem(38px);
    }

    &__text {
        @include pr();
        font-family: var(--work);
        font-size: rem(20px);
        font-weight: 700;
        line-height: 1.4;
        text-align: center;
    }
}
