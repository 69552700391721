.instagram {
    &__gallery {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(76px, 1fr));
        column-gap: rem(16px);
        row-gap: rem(14px);
        padding: rem(28px) rem(38px);
    }

    &__item {
        border-radius: rem(15px);
        overflow: hidden;
        justify-self: center;
    }
}
