.news-full {
    &__sharing {
        margin: rem(90px) 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        gap: 19px;
        --button-border-color: var(--dark-green);
        --button-text-color: var(--dark-green);
        --button-background-color: white;
        --button-hover-background-color: var(--button-text-color);
        --button-hover-text-color: var(--button-background-color);
        --button-hover-border-color: var(--button-border-color);
    
        @media only print {
            display: none !important;
        }
    
        .more-button {
            @include button();
            height: rem(60px);
            box-sizing: border-box;
            font-size: rem(18px);
            letter-spacing: 0.2em;
            text-transform: uppercase;
            position: relative;
    
            @include tablet() {
                height: rem(60px);
                font-size: rem(18px);
            }
        }
    }
}