.news-latest--list,
.news-latest--podcast {
    margin: 0.6em 0;
    font-family: var(--work);
    font-size: rem(20px);
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    padding: 0 rem(60px);

    &:first-child {
        margin-top: rem(26px);
    }

    &:last-child {
        margin-bottom: rem(26px);
    }

    .news-latest {        
        &__headline {
            font-weight: bold;
            margin: 0;
            font-size: inherit;
            font-family: inherit;
            letter-spacing: inherit;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__info {
            margin: 0;
            font-size: 0.9em;
        }

        &__link-wrapper {
            text-decoration: none;

            &:hover {
                color: inherit;
                text-decoration: none;

                .news-latest__headline {
                    text-decoration: underline;
                }
            }
        }
    }
}

.news-latest--podcast {
    padding: 0 rem(50px);

    .news-latest {
        &__headline {
            white-space: wrap;
            overflow: visible;
        }
    }
}
